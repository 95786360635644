










































































































































































































import {Vue, Component, Prop} from 'vue-property-decorator'

import Confirmacion from '@/components/Confirmacion.vue'
import { DespAlmacCabecera } from '@/entidades/DespachoAlmaciguera/DespAlmacCabecera';
import { DespAlmacItem } from '@/entidades/DespachoAlmaciguera/DespAlmacItem';
import {State, Action, Mutation} from 'vuex-class';
import { secondAxios } from '@/plugins/axios';
import { AxiosResponse } from 'axios';
import ResponseCustom from '@/entidades/ResponseCustom';
import { HaciendaModel } from '@/store/Maestros/Hacienda/types';
import { PlantaModel } from '@/store/Maestros/Plantas/types';
import TablaReutilizable from '@/components/TablaReutilizable.vue'
import { TableBtnModel } from '@/entidades/TableBtnModel';
import { mensajes } from '@/plugins/notify';

@Component({
    components:{
        Confirmacion,
        TablaReutilizable
    }
})

export default class extends Vue
{   
    editando=false;
    eliminando=false;
    menu=false;
    haciendaId=-1;
    loteId=-1;
    plantaId=-1;

    @State('haciendas',{namespace:'haciendaModule'}) haciendas!:HaciendaModel[];
    @State('plantas',{namespace:'plantaModule'}) plantas!:PlantaModel[];
    @Action('cargarPlantas', {namespace:'plantaModule'} ) cargarPlantas!: Function;
    local:DespAlmacCabecera=new DespAlmacCabecera()
    itemTmp:DespAlmacItem=new DespAlmacItem();
    itemOriginal:DespAlmacItem=new DespAlmacItem();
    haciendasApi:any[]=new Array();
    haciendasLocal:any[]=new Array();
    anioCultivo:any='';
    headers:any[]= 
    [
        {
          text: 'Hacienda',
          align: 'start',
          sortable: false,
          value: 'Hacienda.descripcion'
        },
        {
          text: 'Lote',
          align: 'start',
          sortable: false,
          value: 'Lote.descripcion'
        },
        {
          text: 'Planta',
          align: 'start',
          sortable: false,
          value:'Planta.descripcion'
        },
        {
          text: 'Cantidad',
          align: 'end',
          sortable: false,
          value:'Cantidad'
        },
        { text: 'Acciones', align: 'center', value: 'acciones', sortable: false },     
    ];
    botones:TableBtnModel[]=[
	{
		icon:'fa-edit',
		label:'',
		action:'AbrirEditarAlmaciguera',
		tooltip:'Editar',
		necesitaIndex:true
	},
	{
		icon:'fa-trash',
		label:'',
		action:'AbrirEliminarAlmaciguera',
		tooltip:'Eliminar',
		necesitaIndex:true
	}
];
    localAlmaciguera:DespAlmacItem=new DespAlmacItem();
    indiceEdicion=-1;
    get progress1(){
        var salida=0;
        if(this.local.fecha){
            salida+=50;
        }
        if(this.local.items.length>0){
            salida+=50;
        }
        return salida;
    }
    get progress2(){
        var salida=0;
        if(this.itemTmp.Hacienda.haciendaId){
            salida+=25;
        }
        if(this.itemTmp.Lote.loteId){
            salida+=25;
        }
        if(this.itemTmp.Cantidad){
            salida+=25;
        }
        if(this.itemTmp.Planta.plantaId){
            salida+=25;
        }
        return salida;
    }
    AbrirEditarAlmaciguera(valor:any){
        this.editando=true;
        this.indiceEdicion=valor.index;
        var item: DespAlmacItem=valor.item
        console.log('Editando: ', item);
        var _ = require('lodash');
        this.itemTmp=_.cloneDeep(item);
        this.itemOriginal=_.cloneDeep(item);
        this.haciendaId=item.Hacienda.haciendaId;
        this.loteId=item.Lote.loteId;
        this.plantaId=item.Planta.plantaId;
    }
    AbrirEliminarAlmaciguera(valor:any){
        this.indiceEdicion=valor.index;
        var item: DespAlmacItem=valor.item;
        console.log('Eliminando: ', item)
        //this.itemTmp=item;
        this.eliminando=true;
    }
    confirmarEliminar(){
        if(this.indiceEdicion>=0){
            var item=this.local.items[this.indiceEdicion];
            this.restarValor(item.Hacienda.haciendaId, item.Lote.loteId, item.Cantidad, true);
            this.local.items.splice(this.indiceEdicion,1);
            this.eliminando=false;
            mensajes.exito('Eliminado')
            this.indiceEdicion=-1;
        }else{
            console.log('Indice invalido')
        }
    }
    cancelarEliminar(){
        this.eliminando=false;
    }
    cambioHacienda(item:any){
        console.log("Hacienda", item)
        this.haciendaId=item.haciendaId;
        this.loteId=-1;
    }
    cambioLote(item:any){
        console.log("Lote", item)
        this.loteId=item.loteId;
    }
    cambioPlanta(item:any){
        console.log("Planta", item)
        this.plantaId=item.plantaId;
    }

    registroValido(){
        var cantidad=Number(this.itemTmp.Cantidad);
        var salida={
            value:false,
            message:''
        }
        if(this.loteId!=-1 && cantidad>0 && this.plantaId!=-1){
            if(cantidad<=this.CantidadDisponible){
                salida.value= true;
            }else{
                salida.value= false;
                salida.message='Cantidad inválida'
            }
        }else{
            salida.value= false;
            salida.message='Faltan campos'
        }
        return salida;
    }
    agregarItem(){
        var resp=this.registroValido()
        if(resp.value){
            var _ = require('lodash')
            if(!this.editando){
                var cantidad=this.itemTmp.Cantidad;
                this.local.items.push(_.cloneDeep(this.itemTmp));
                console.log('Agregado!');
                this.restarValor(this.itemTmp.Hacienda.haciendaId, this.itemTmp.Lote.loteId, cantidad, false);
                this.itemTmp.Cantidad=0;
                this.limpiar();
            }else{
                var cantidadOriginal=Number(this.itemOriginal.Cantidad);
                var cantidadNueva=Number(this.itemTmp.Cantidad);
                this.restarValor(this.itemOriginal.Hacienda.haciendaId, this.itemOriginal.Lote.loteId, cantidadOriginal, true);
                this.restarValor(this.itemTmp.Hacienda.haciendaId, this.itemTmp.Lote.loteId, cantidadNueva, false);
                
                if(this.indiceEdicion>=0){
                    this.local.items.splice(this.indiceEdicion,1);
                    this.local.items.push(_.cloneDeep(this.itemTmp));
                    this.itemTmp.Cantidad=0;
                    this.editando=false;
                    this.indiceEdicion=-1;
                }else{
                    mensajes.error('No se pudo editar')
                }
                this.limpiar();
            }

        }else{
            mensajes.error(resp.message)
        }
        //restar Stock
    }
    get labelAccion(){
        if(this.editando){
            return "Editar"
        }else{
            return "Agregar"
        }
    }
    limpiar(){
        this.itemTmp=new DespAlmacItem();
        this.haciendaId=-1;
        this.loteId=-1;
        this.plantaId=-1;
    }
    restarValor(haciendaId:number, loteId:number, cantidad:number, esSuma:boolean){
        var indiceHacienda=this.haciendasLocal.findIndex(e=>e.haciendaId==haciendaId);
        if(indiceHacienda>=0){
            //@ts-ignore
            var indiceLote=this.haciendasLocal[indiceHacienda].lotes.findIndex(e=>e.loteId==loteId);
            if(indiceLote>=0){
                var valorOriginal=Number(this.haciendasLocal[indiceHacienda].lotes[indiceLote].cantidad);
                if(esSuma){
                    this.haciendasLocal[indiceHacienda].lotes[indiceLote].cantidad=valorOriginal+Number(cantidad);
                }else{
                    this.haciendasLocal[indiceHacienda].lotes[indiceLote].cantidad=valorOriginal-Number(cantidad);
                }
            }else{
                mensajes.error('Lote no encontrado')
            }
        }else{
            mensajes.error('Hacienda no encontrada')
        }
    }
    get CantidadDisponible(){
        if(this.loteId!=-1){
            var hac= this.haciendasLocal.find(e=>e.haciendaId==this.itemTmp.Hacienda.haciendaId);
            if(hac){
                //@ts-ignore
                var lot=hac.lotes.find(e=>e.loteId==this.loteId);
                if(lot){
                    return lot.cantidad
                }
            }
        }
    }
    async cargarHaciendas(){
        try{
            mensajes.advertencia('Cargando haciendas, por favor espere...')
            const responde: AxiosResponse=await secondAxios({
                url:'Despacho/ObtenerAlmacigueraHaciendaLote',
                method:'GET'
            });
            const payload:ResponseCustom<any[]>=responde && responde.data;
            //@ts-ignore
            if(payload.isSuccess){
                //@ts-ignore
                this.haciendasApi=payload.isResult;
                //@ts-ignore

                var _=require('lodash')
                this.haciendas.forEach(hacienda=>{
                    var lotes=new Array();
                    hacienda.lotes.forEach(lote=>{
                        var tmp=this.haciendasApi.find(e=>e.haciendaId==hacienda.haciendaId && e.loteId==lote.loteId);
                        if(tmp){
                            var nuevoLote=_.cloneDeep(lote);
                            nuevoLote.cantidad=tmp.cantidad;
                            lotes.push(nuevoLote)
                            //this.haciendasLocal.push(nuevoLote)
                            console.log('Creado lote modificado: ', nuevoLote)
                        }        
                    })
                    if(lotes.length>0){
                        var haciendaNueva=_.cloneDeep(hacienda);
                        haciendaNueva.lotes=lotes;
                        console.log('Creado hacienda modificada: ', haciendaNueva)
                        this.haciendasLocal.push(haciendaNueva);
                    }
                })
                console.log('EXITO', payload)
                mensajes.exito('Haciendas cargadas correctamente')
            }else{
                mensajes.error('Error al obtener las haciendas: '+payload.isMessage);
            }
        } catch(e){
            mensajes.error('Error al obtener las haciendas: No se pudo establecer una conexión con el servidor');
        }
    }

    async Enviar(){
        if(this.local.fecha!=''){
            if(this.local.items.length>0){
                var salida=DespAlmacCabecera.preparar(this.local);
                console.log(JSON.stringify(salida));
                salida.usuarioId=this.$store.state.authModule.usuarioId;
                try{
                    await secondAxios({
                        url:`Despacho/GuardarDespachoAlmaciguera`,
                        method:'POST',
                        data:salida
                    }).then(async (data)=>{
                        var resp:ResponseCustom<any>=data.data;
                        console.log("Respuesta; ", resp)
                        if(resp.isSuccess){
                            console.log('Enviado');
                            await this.cargarHaciendas();
                            this.local=new DespAlmacCabecera();
                            this.itemTmp=new DespAlmacItem();
                        }else{
                            mensajes.error('Error: '+ resp.isMessage);
                        }
                    }).catch((err)=>{
                        mensajes.errorDeConexion();
                    })
                }catch(err){
                    mensajes.errorDeConexion();
                }
            }else{
                mensajes.error('Debe ingresar los despachos')
            }
        }else{
            mensajes.error('Fecha inválida')
        }
    }

    async created() {
        //Cargar Plantas
        mensajes.advertencia('Cargando plantas...')
        await this.cargarPlantas();
        
        //Cargar Haciendas   
        await this.cargarHaciendas();
    }
}
