import { LoteModel } from "@/store/Maestros/Hacienda/types";
import { PlantaModel } from "@/store/Maestros/Plantas/types";
import {Planta} from "../Maestro/Planta";
import Hacienda from "../Plantacion/Hacienda";

export class DespAlmacItem{
    Planta:PlantaModel=new Planta();
    Cantidad:number=0;
    Hacienda: Hacienda=new Hacienda();
    Lote:LoteModel=new LoteModel()

    static preparar(entrada: DespAlmacItem):AlmacigueraItemApi{
        var salida:AlmacigueraItemApi=new AlmacigueraItemApi();
        salida.cantidad=entrada.Cantidad;
        salida.haciendaId=entrada.Hacienda.haciendaId;
        salida.loteId=entrada.Lote.loteId;
        salida.plantaId=entrada.Planta.plantaId;
        return salida;
    }
}
export class AlmacigueraItemApi{
    plantaId:number=0;
    cantidad:number=0;
    haciendaId:number=0;
    loteId:number=0;
}