import { AlmacigueraItemApi, DespAlmacItem } from "./DespAlmacItem";

export class DespAlmacCabecera{
    items:DespAlmacItem[]=[];
    fecha:string='';
    observacion:string='';
    usuarioId:string='';
    static preparar(entrada:DespAlmacCabecera):DespachoCabeceraApi{
        var salida:DespachoCabeceraApi=new DespachoCabeceraApi();
        salida.fecha=entrada.fecha;
        salida.observacion=entrada.observacion;
        entrada.items.forEach(item=>{
            salida.items.push(DespAlmacItem.preparar(item));
        })
        return salida;
    }
}
export class DespachoCabeceraApi{
    fecha:string='';
    observacion:string='';
    usuarioId:string='';
    items:AlmacigueraItemApi[]=[]
}